export function personalizations(key) {
  switch (key) {
    case 'if_customer_tags':
      return "<p>{% if customer.tags contains 'My tag' %}<br>The contact totally has this tag.<br>{% else %}<br>Nope, the contact doesn't have the tag.<br>{% endif %}</p>"
    case 'if_logged_in':
      return "<p>{% if app.logged_in? %}<br>You're totally logged in, {{ current_user.first_names }}.<br>{% else %}<br>Not logged in today.<br>{% endif %}</p>"
    case 'if_customer':
      return "<p>{% if customer %}<br>Hello, {{ customer.first_names }}!<br>{% else %}<br>Don't know you.<br>{% endif %}</p>"
    default:
      return `{{ ${key} }}`
  }
}
