import { Extension } from '@tiptap/core'

import { linkInfoDisplayBoxPlugin } from '~/admin/components/prosemirror/plugins/link_info_display_box'

export const LinkInfoBox = Extension.create({
  addProseMirrorPlugins() {
    return [
      linkInfoDisplayBoxPlugin(),
    ]
  },
})
